import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let TaloLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 62 26"}>
		<title>Vety</title>

		<g>
			<g>
				<path
					fill="#222222"
					d="M7.42,24.76V5H0.7V0.7h17.98V5h-6.72v19.76H7.42z"
				/>
				<path
					fill="#222222"
					d="M29,23.01h-0.27c-0.52,0.63-1.14,1.17-1.87,1.61c-0.73,0.45-1.66,0.67-2.81,0.67
			c-0.85,0-1.65-0.14-2.4-0.42c-0.75-0.28-1.41-0.67-1.97-1.16c-0.56-0.49-1-1.09-1.31-1.78c-0.31-0.69-0.47-1.46-0.47-2.29
			c0-0.85,0.17-1.62,0.5-2.3s0.81-1.27,1.41-1.76c0.6-0.49,1.32-0.87,2.13-1.13c0.82-0.26,1.71-0.39,2.67-0.39
			c1.08,0,1.96,0.08,2.65,0.25c0.69,0.17,1.27,0.34,1.71,0.52v-0.5c0-0.87-0.35-1.6-1.04-2.17c-0.69-0.57-1.56-0.86-2.59-0.86
			c-1.52,0-2.81,0.64-3.86,1.92l-3.19-2.18c1.75-2.2,4.13-3.29,7.16-3.29c2.55,0,4.5,0.6,5.85,1.8c1.34,1.2,2.02,2.97,2.02,5.33
			v9.88H29V23.01z M29,18.07c-0.52-0.25-1.05-0.44-1.6-0.59c-0.55-0.15-1.15-0.22-1.8-0.22c-1.05,0-1.87,0.23-2.44,0.69
			s-0.86,1.03-0.86,1.7s0.27,1.21,0.82,1.61s1.18,0.61,1.9,0.61c0.58,0,1.12-0.11,1.61-0.32c0.49-0.21,0.91-0.49,1.26-0.84
			c0.35-0.35,0.62-0.75,0.81-1.21S29,18.57,29,18.07z"
				/>
				<path fill="#222222" d="M36.83,24.76V0.7h4.4v24.06H36.83z" />
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#222222"
				d="M48.26,16.15c0-1.97,1.27-3.64,3.03-4.24v-4.2
		c-4.04,0.69-7.12,4.21-7.12,8.44c0,4.73,3.83,8.56,8.56,8.56c4.24,0,7.76-3.08,8.44-7.12h-4.2c-0.6,1.76-2.27,3.03-4.24,3.03
		C50.26,20.63,48.26,18.62,48.26,16.15"
			/>
			<polygon
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#33CC66"
				points="52.73,7.59 52.73,11.67 57.21,11.67 57.21,16.15 
		61.3,16.15 61.3,11.67 61.3,7.59 57.21,7.59 	"
			/>
		</g>
	</SvgIcon>
);

TaloLogo = memo(TaloLogo);
TaloLogo.displayName = "TaloLogo";
TaloLogo.muiName = "TaloLogo";

export default TaloLogo;
